.ffk-hero {
  padding: 2rem 0 4rem 0;

  @include media-breakpoint-up(md) {
    padding: 7rem 0 8rem 0;
  }

  img {
    border-radius: 7em 2em 6em 2em;
  }
}
