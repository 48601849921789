//---------------------------------------------
//
//  Fonts
//
//    1. Loading Mixins
//    2. Loading Fonts
//
//---------------------------------------------

//---------------------------------------------
// 1. Loading Mixins
//---------------------------------------------

// @import "mixins/google-fonts";

//---------------------------------------------
// 2. Loading Fonts
//---------------------------------------------

// @include googleFont(
//   (
//     "name": "Roboto",
//     "folder": "Roboto",
//     "files": "Roboto",
//     "weights": (
//       300,
//       400,
//       700
//     )
//   )
// );

/*
 * Example for loading multiple fonts at once
 *
@include googleFonts((
  (
    "name": "Roboto",
    "folder": "Roboto",
    "files": "Roboto",
    "weights": (300, 400, 700)
  ),
  (
    "name": "Besley",
    "folder": "Besley",
    "files": "Besley",
    "weights": (300, 400, 700)
  ),
));
*/
/* poppins-300 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("../webfonts/poppins/poppins-v19-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""), url("../webfonts/poppins/poppins-v19-latin-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../webfonts/poppins/poppins-v19-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../webfonts/poppins/poppins-v19-latin-300.woff") format("woff"),
    /* Modern Browsers */ url("../webfonts/poppins/poppins-v19-latin-300.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../webfonts/poppins/poppins-v19-latin-300.svg#Poppins") format("svg"); /* Legacy iOS */
}
/* poppins-300italic - latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  src: url("../webfonts/poppins/poppins-v19-latin-300italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../webfonts/poppins/poppins-v19-latin-300italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../webfonts/poppins/poppins-v19-latin-300italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../webfonts/poppins/poppins-v19-latin-300italic.woff") format("woff"),
    /* Modern Browsers */ url("../webfonts/poppins/poppins-v19-latin-300italic.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../webfonts/poppins/poppins-v19-latin-300italic.svg#Poppins") format("svg"); /* Legacy iOS */
}
/* poppins-500 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url("../webfonts/poppins/poppins-v19-latin-500.eot"); /* IE9 Compat Modes */
  src: local(""), url("../webfonts/poppins/poppins-v19-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../webfonts/poppins/poppins-v19-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../webfonts/poppins/poppins-v19-latin-500.woff") format("woff"),
    /* Modern Browsers */ url("../webfonts/poppins/poppins-v19-latin-500.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../webfonts/poppins/poppins-v19-latin-500.svg#Poppins") format("svg"); /* Legacy iOS */
}
/* poppins-500italic - latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  src: url("../webfonts/poppins/poppins-v19-latin-500italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../webfonts/poppins/poppins-v19-latin-500italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../webfonts/poppins/poppins-v19-latin-500italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../webfonts/poppins/poppins-v19-latin-500italic.woff") format("woff"),
    /* Modern Browsers */ url("../webfonts/poppins/poppins-v19-latin-500italic.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../webfonts/poppins/poppins-v19-latin-500italic.svg#Poppins") format("svg"); /* Legacy iOS */
}

h2 {
  margin: 0.8rem 0 0.6rem 0;
}

h2,
h3,
h4 {
  // margin: 1.5rem 0 1rem 0;
}

a.anchor {
  font-size: inherit;
  font-style: inherit;
  color: inherit;
  text-decoration: inherit;
}

.ffk-col-right a {
  color: #fff;
}
