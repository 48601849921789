// Global Bootstrap Variables
@import "variables";

// Import Bootstrap
@import "bootstrap";

//
// Custom styles
//
@import "fonts";
@import "layout";
@import "nav";
@import "content-elements";
@import "icon-list";
@import "feature";
@import "bookings";

body {
  // font-family: Poppins, sans-serif !important;
  padding-top: 87px;
  // background-color: green !important;
}

// Style Bootstrap icons
.bi {
  fill: currentColor;
}
