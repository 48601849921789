.container {
  > .ffk-row-content ~ .ffk-row-content {
    margin-top: 50px;
  }
}

.ffk-hero {
  margin-top: -1px;
}

.btn:focus,
.btn:focus:active,
.form-control,
.form-select,
.form-check-input {
  box-shadow: none !important;
}

// Anchor offset
// a.anchor {
//   display: block;
//   position: relative;
//   top: -250px;
//   visibility: hidden;
// }

:target:before {
  content: "";
  display: block;
  height: 75px;
  margin: -75px 0 0;
}

footer {
  .nav a,
  p {
    color: #fff;
    // color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
    &:hover {
      color: #fff;
    }
  }
}
