// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.

/* 
-------------
    Fonts
-------------
*/
$enable-gradients: false;
$enable-shadows: false;
$fa-font-path: "../../dist/webfonts/fa";

$font-family-sans-serif: "Poppins";
$font-family-base: $font-family-sans-serif !default;
$font-size-base: 1.0rem;
$line-height-base: 1.48;
// $line-height-sm: 4.25;
// $line-height-lg: 5;
$font-weight-bold: 500;
$font-weight-bolder: 500;

/* 
-------------
    Colors
-------------
*/

// $min-contrast-ratio: 3;
// $offcanvas-box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);

$body-color: #333;
$body-bg: #ffffff;
$border-radius: 0.4rem;
$success: #7952b3;

// $primary: #ccd5ae;
$primary: #204317;
// $secondary: #204317;
$secondary: #ccd5ae;
$light: #ccd5ae;
$dark: $primary;

// $navbar-dark-color: rgba($secondary, 0.55);
$navbar-nav-link-padding-x: 1rem;

/* 
-------------
    Globals
-------------
*/
$btn-border-radius: 1rem;
$input-border-radius: 1rem;
$input-focus-border-color: $secondary;
$input-focus-box-shadow: none;
$btn-box-shadow: none;
$btn-focus-box-shadow: none;
$input-btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
// $spacer: 1.5rem;

// Forms
// Floating Labels
// $form-floating-height: add(3.5rem, $input-height-border);
// $form-floating-line-height: 1.25;
$form-floating-padding-x: 1rem;
$form-floating-padding-y: 0.97rem;
$form-floating-input-padding-t: 1.1rem;
// $form-floating-input-padding-b: 0.625rem;
// $form-floating-label-opacity: 0.65;
$form-floating-label-transform: scale(0.8) translateY(-0.9rem) translateX(0.44rem);
// $form-floating-transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
