// Override litepicker sizes
$lp-day-size-xs: 63px;
$lp-day-size-sm: 72px;
$lp-day-size-md: 63px;
$lp-day-size-lg: 42px;
$lp-day-size-xl: 51px;
$lp-day-size-xxl: 59px;

#booking-form {
  position: relative !important;
  margin-top: 1rem;

  .btn {
    min-width: 190px;
  }

  .litepicker {
    font-family: "Poppins";

    .container__months {
      border: $input-border-width solid;
      border-color: $input-border-color;
      border-radius: $input-border-radius;
      box-shadow: none;
    }

    .is-locked {
      color: #fff !important;
      background-color: #f00 !important;
    }

    .is-today {
      color: #333 !important;
    }
  }

  // Override Litepicker sizes

  // @include media-breakpoint-up(xs) {
  //   .litepicker .container__months {
  //     width: calc(#{$lp-day-size-xs} * 7 + 10px);
  //   }
  //   .litepicker .container__months .month-item {
  //     width: calc(#{$lp-day-size-xs} * 7);
  //   }
  //   .litepicker .container__days > div,
  //   .litepicker .container__days > a {
  //     width: calc(100% / 7);
  //     padding: 15px 0;
  //   }
  // }

  @include media-breakpoint-up(sm) {
    .litepicker .container__months.columns-2 {
      width: 100%;
    }
    .litepicker .container__months {
      width: calc(#{$lp-day-size-sm} * 7 + 10px);
    }
    .litepicker .container__months .month-item {
      width: calc(#{$lp-day-size-sm} * 7);
    }
    .litepicker .container__days > div,
    .litepicker .container__days > a {
      width: $lp-day-size-sm;
      padding: 15px 0;
    }
  }

  @include media-breakpoint-up(md) {
    .litepicker .container__months {
      width: calc(#{$lp-day-size-md} * 7 + 10px);
    }
    .litepicker .container__months .month-item {
      width: calc(#{$lp-day-size-md} * 7);
    }
    .litepicker .container__days > div,
    .litepicker .container__days > a {
      width: $lp-day-size-md;
      padding: 15px 0;
    }
  }

  @include media-breakpoint-up(lg) {
    .litepicker .container__months .month-item {
      width: calc(#{$lp-day-size-lg} * 7);
    }
    .litepicker .container__days > div,
    .litepicker .container__days > a {
      width: $lp-day-size-lg;
      padding: 10px 0;
    }
  }

  @include media-breakpoint-up(xl) {
    .litepicker .container__months .month-item {
      width: calc(#{$lp-day-size-xl} * 7);
    }
    .litepicker .container__days > div,
    .litepicker .container__days > a {
      width: $lp-day-size-xl;
      padding: 10px 0;
    }
  }

  @include media-breakpoint-up(xxl) {
    .litepicker .container__months .month-item {
      width: calc(#{$lp-day-size-xxl} * 7);
    }
    .litepicker .container__days > div,
    .litepicker .container__days > a {
      width: $lp-day-size-xxl;
      padding: 15px 0;
    }
  }
}

// Animation on success
form.form-success div.form-floating,
form.form-success button#btn-booking {
  opacity: 0;
  /* visibility: hidden; */
  animation: fade 0.6s ease-in-out;
}

div.booking-success {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  visibility: hidden;
}

div.booking-success {
  transition: visibility 0s 2s;
}

form.form-success > .booking-success {
  opacity: 1;
  visibility: visible;
  transition: opacity 1s 0.7s ease-in-out;
  /* 	animation: done 2s 1s ease-in-out; */
}

@keyframes fade {
  from {
    opacity: 1;
    /* visibility: visible; */
  }
  to {
    opacity: 0;
    /* visibility: hidden; */
  }
}

@keyframes done {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #204317;
  fill: none;
}

.form-success .checkmark__circle {
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) 0.9s forwards;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #204317;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #204317;
}

.form-success .checkmark {
  animation: fill 0.4s ease-in-out 1.2s forwards, scale 0.3s ease-in-out 1.7s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
}

.form-success .checkmark__check {
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 1.6s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #fff;
  }
}
