nav {
  &.navbar {
    // margin-bottom: 20px;
    background-image: none;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    transition: all 0.5s;

    .navbar-collapse {
      flex-grow: 0;
    }

    .nav-item {
      // margin-right: $me-4;
    }

    &.shrink {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      // background-color: red!important;
    }
  }
}
